import React, { useState, useEffect } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path accordingly
import { NumberTo2DP } from '../../utils/NumberTo2DP';
import OverviewStat from '../overview/OverviewStat';
import OverviewBarCharts from '../../component/overview/OverviewBarCharts'
import OverviewTable from './OverviewTable';

const OverviewCom = () => {
    const myDate = new Date()
    const MAX_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${myDate.getDate()  >= 10 ? myDate.getDate() : `0${myDate.getDate()}` }`
    const MIN_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${'01'}`

    const [period, setPeriod] = useState({
        start_date: MIN_DATE,
        end_date: MAX_DATE
    });
    
    const [country, setCountry] = useState('kenya'); // Default to Kenya

    const [stats, setStats] = useState({loading: true, data:{}});

    const handlePeriodChange = ({target:{name, value}}) => {
        setPeriod(prev => ({...prev, [name]:value}))
    }

    useEffect(() => {
        const fetchFinanceStats = async () => {
            const token = getToken(); // Retrieve the token

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`);
            }

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            setStats(prev => ({...prev, loading: true}))
            try {
                // const response = await fetch(`https://api.quickcare.ng/admin/financials/stats?period=${period}`, requestOptions);
                const response = await fetch(`https://api.quickcare.ng/admin/financials/stats?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
                const result = await response.json();
                // setStats(result.data);
                setStats({loading: false, data:result.data})
            } catch (error) {
                setStats({loading: false, data:{}})
                console.error('Error fetching financial stats:', error);
            }
        };

        fetchFinanceStats();
    }, [period, country]); // Fetch stats whenever the period changes

    const getPeriodLabel = () => {
        switch (period) {
            case '24hr':
                return "Today's Revenue";
            case 'all':
                return "All Revenue";
            case '1week':
                return "This Week Revenue";
            case '2weeks':
                return "2 Weeks Revenue";
            case '1month':
                return "This Month Revenue";
            case '2months':
                return "2 Months Revenue";
            case 'quarter':
                return "Quarter Revenue";
            case '6months':
                return "6 Months Revenue";
            case '1year':
                return "1 Year Revenue";
            default:
                return "Revenue";
        }
    };

    return (
        <>
            <div className='w-full flex flex-col gap-4'>
                <div className='flex w-full items-center justify-end gap-2 lg:gap-4'>
                    <div className='flex flex-col sm:flex-row gap-2 lg:gap-4'>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>Start Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                max={period.end_date}
                                name='start_date' 
                                value={period.start_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>End Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                min={period.start_date}
                                max={MAX_DATE}
                                name='end_date' 
                                value={period.end_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                    <label className='text-black font-bold'>Country</label>
                        <select id="country" value={country} onChange={(e) => setCountry(e.target.value)}
                            className='p-3 rounded-lg bg-white outline-0'>
                            <option value="kenya">Kenya</option>
                            <option value="nigeria">Nigeria</option>
                        </select>
                    </div>
                </div>

                <OverviewStat period={period} country={country} />

                {stats && (
                    <div className='flex flex-col'>
                        <p className='mt-4 mb-2 text-xl text-[#424242] font-semibold'>
                        Subscription
                        </p>
                        <div style={{ marginTop: '10px' }} className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 w-full items-center justify-between gap-3
                        lg:justify-start lg:gap-5'>
                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>{getPeriodLabel()} amount</h3>
                                <p>{stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.from_month_start_amount) || 0}</p>
                            </div>

                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>{getPeriodLabel()} count</h3>
                                <p>{stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.from_month_start_count) || 0}</p>
                            </div>

                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>Total subscriptions amount</h3>
                                <p>
                                    {stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.total_subscriptions_amount) || 0}
                                    {/* {country === 'kenya' ? stats?.total_subscriptions_amount|| 0 : stats?.todays_subscription?.Nigeria || 0} */}
                                </p>
                            </div>

                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>Total subscriptions count</h3>
                                <p>{stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.total_subscriptions_count) || 0}</p>
                            </div>

                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>Today subscription amount</h3>
                                <p>{stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.today_subscription_amount) || 0}</p>
                            </div>

                            <div className='p-4 rounded-lg bg-white shadow text-black flex flex-col gap-2'>
                                <h3 className='font-semibold'>Today subscription count</h3>
                                <p>{stats.loading ? 'loading...' : NumberTo2DP(stats?.data?.today_subscription_count) || 0}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className='w-full mt-6 p-3 bg-white rounded-lg'>
                    <div className='w-full flex items-center justify-between font-semibold mb-3'>
                        <p>
                            Total Patients Per Month
                        </p>
    
                    </div>
                
                    <OverviewBarCharts period={period} country={country}  />
                </div>

                <OverviewTable period={period} country={country}  />
            </div>
        </>
    );
};

export default OverviewCom;