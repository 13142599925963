import React from 'react'

import TableWrapper from '../wrappers/TableWrapper'
import { NumberTo2DP } from '../../utils/NumberTo2DP';


export default function MarketTable({tableData}) {

    let {loading, error, data} = tableData
    if (loading) return <div>Loading data...</div>;
    if (error) return <div>{error}</div>;

  return (
    <div className='w-full'>
        <div className='w-full flex flex-col gap-4'>
            <div className='overflow-auto bg-green-500'>
                <TableWrapper data={data} itemsPerPage={10}>
                    {({ data }) => {

                        let totalPlayers = data.length ? data?.map(item => item?.total_revenue ? item?.total_revenue : 0)?.reduce((accumulator ,item) => {
                            return accumulator += item;
                        }, 0) : 0

                        let totalCustomers = data.length ? data?.map(item => item?.total_customers ? item?.total_customers : 0)?.reduce((accumulator ,item) => {
                            return accumulator += item;
                        }, 0) : 0

                        let monthlyRevenue = data.length ? data?.map(item => item?.monthly_revenue ? item?.monthly_revenue : 0)?.reduce((accumulator ,item) => {
                            return accumulator += item;
                        }, 0) : 0

                        let activeCustomers = data.length ? data?.map(item => item?.active_customers ? item?.active_customers : 0)?.reduce((accumulator ,item) => {
                            return accumulator += item;
                        }, 0) : 0

                        let churnRate = data.length ? data?.map(item => item?.churn_rate ? item?.churn_rate : 0)?.reduce((accumulator ,item) => {
                            return accumulator += item;
                        }, 0) : 0

                        return (
                            <>
                                <table className='min-w-full bg-white dark:bg-transparent border border-gray-200 rounded-lg shadow-md text-sm'>
                                    <thead>
                                        <tr className='bg-gray-100 border-b'>
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Marketing partner</th>
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Total revenue</th>
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Total No. of customers</th>
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Monthly revenue</th>
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Active customers</th>
                                            {/* <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Opt-out customers</th> */}
                                            <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Monthly churned customers</th>
                                            {/* <th className='px-4 py-3 text-left font-semibold text-gray-700 '>Last active</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data?.length > 0 ?
                                        <>
                                        {data.map((keyword, index) => (
                                            <tr key={index} className='border-b hover:bg-gray-50 dark:hover:bg-gray-500 cursor-pointer'>
                                                <td className='flex items-center gap-2 px-4 py-2 text-gray-800 dark:text-white-light'>
                                                    <img className='w-10 h-10 rounded-sm' src={''} alt='marketer logo' />
                                                    <span className='pr-4'>{keyword?.keyword}</span>
                                                </td>
                                                <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.total_revenue)}</td>
                                                <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.total_customers)}</td>
                                                <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.monthly_revenue)}</td>
                                                <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.active_customers)}</td>
                                                {/* <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.total_revenue)}</td> */}
                                                <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.churn_rate)}</td>
                                                {/* <td className='px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(keyword?.total_revenue)}</td> */}
                                            </tr>
                                        ))
                                        }
                                            <tr className='border-b hover:bg-gray-50 dark:hover:bg-gray-500 cursor-pointer'>
                                                <td className='font-bold flex items-center gap-2 px-4 py-2 text-gray-800 dark:text-white-light'>
                                                    {/* <img className='w-10 h-10 rounded-sm' src={MarketerIcon} alt='marketer logo' /> */}
                                                    <span className='pr-4'>TOTAL</span>
                                                </td>
                                                <td className='font-bold px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(totalPlayers)}</td>
                                                <td className='font-bold px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(totalCustomers)}</td>
                                                <td className='font-bold px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(monthlyRevenue)}</td>
                                                <td className='font-bold px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(activeCustomers)}</td>
                                                <td className='font-bold px-4 py-2 text-gray-800 dark:text-white-light'>{NumberTo2DP(churnRate)}</td>
                                            </tr>
                                        </>
                                        :
                                        <tr className='border-b hover:bg-gray-50'>
                                            <td colSpan={6} className='text-center px-4 py-2 text-gray-800 dark:text-white-light'>
                                                Opps! No Data Found.
                                            </td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </>
                        )
                    }
                    }
                </TableWrapper>
            </div>
        </div>
    </div>
  )
}
