import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/getToken';
import { NumberTo2DP } from '../../utils/NumberTo2DP';

export default function OverviewTable({ period, country }) {
  const [response, setResponse] = useState({loading: true, error:false, data: []});

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            if (!token) {
                setResponse({loading: false, error:true, data:[]})
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            setResponse({loading: true, error:false, data: []})

            try {
                const response = await fetch(`https://api.quickcare.ng/admin/overview/table-data?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
                const result = await response.json();
                console.log("Data received...:", result);
                setResponse({loading: false, error:false, data: result.data})
            } catch (error) {
                setResponse({loading: false, error:true, data:[]})
            }
        };

        fetchData();
    }, [period, country]);

    let totalPlayers = response?.data.length ? response?.data?.map(item => item?.total_players ?  item?.total_players : 0)?.reduce((accumulator, item) => {
        return accumulator += item;
    }, 0) : 0

    console.log(totalPlayers)

    let totalRevenue = response?.data.length ? response?.data?.map(item => item?.total_revenue ? item?.total_revenue : 0)?.reduce((accumulator, item) => {
        return accumulator += item;
    }, 0) : 0

    return (
        <div className="mt-6">
            <h1 className="text-[#22232D] dark:text-white-light text-xl font-semibold mb-4">Overview Table</h1>
            <div className="overflow-hidden overflow-x-auto">
            <table className='min-w-full bg-white dark:bg-transparent border border-gray-200 rounded-lg shadow-md text-sm md:text-lg'>
                <thead>
                <tr className='bg-gray-100 border-b'>
                    <th className="px-4 py-3 text-left font-semibold text-gray-700">Country</th>
                    <th className="px-4 py-3 text-left font-semibold text-gray-700">Keyword</th>
                    <th className="px-4 py-3 text-left font-semibold text-gray-700">Total Player</th>
                    <th className="px-4 py-3 text-left font-semibold text-gray-700">Revenue</th>
                </tr>
                </thead>
                <tbody>
                { response.loading ?
                <td colSpan={5} className="px-4 py-2 text-center text-gray-800 dark:text-white-light">Loading...</td>
                : response.error ?
                <td colSpan={5} className="px-4 py-2 text-center text-gray-800 dark:text-white-light">Opps! Error Occured</td>
                :(response && response?.data?.length) ?
                <>
                    {/* {renderTableData()} */}
                {response && response.data.map((item, index)=>{
                    return (
                    <tr key={`${index}`} className='border-b hover:bg-gray-50 dark:hover:bg-gray-500 cursor-pointer'>
                        <td className="px-4 py-2 text-gray-800 dark:text-white-light">{item.country}</td>
                        <td className="px-4 py-2 text-gray-800 dark:text-white-light">{item.keyword}</td>
                        <td className="px-4 py-2 text-gray-800 dark:text-white-light">{item.total_players}</td>
                        <td className="px-4 py-2 text-gray-800 dark:text-white-light">{NumberTo2DP(item.total_revenue)}</td>
                    </tr>
                    )
                })}
                <tr className='border-b hover:bg-gray-50 dark:hover:bg-gray-500 cursor-pointer'>
                    <td className="font-bold px-4 py-2 text-gray-800 dark:text-white-light">TOTAL</td>
                    <td className="px-4 py-2 text-gray-800 dark:text-white-light"></td>
                    <td className="font-bold px-4 py-2 text-gray-800 dark:text-white-light">{totalPlayers}</td>
                    <td className="font-bold px-4 py-2 text-gray-800 dark:text-white-light">{NumberTo2DP(totalRevenue)}</td>
                </tr>
                </>
                :
                <td colSpan={4} className="px-4 py-2 text-center text-gray-800 dark:text-white-light">No record found</td>
                }
                </tbody>
            </table>
            </div>
        </div>
    );
}
