import { useEffect, useState } from "react";

export default function TableWrapper({
  data = [],
  itemsPerPage = 5,
  filterItem,
  children,
}) {

  data = Array.isArray(data) ? data : Object?.keys(data).map(keyword => ({keyword, ...data[keyword]}))
  // if(!Array.isArray(data)){
  //   if(Object?.keys(data)?.length > 0){
  //     data = Object?.keys(data).map(keyword => ({keyword, ...data[keyword]}))
  //   }else{
  //     data = []
  //   }
  // }

  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const [currentPage, setCurrentPage] = useState(0);
  const [newData, setNewData] = useState([]);

  const numberOfSelection = itemsPerPage;

  const handlePrev = () => {
    if (currentPage != 0) {
      setCurrentPage((prev) => prev - numberOfSelection);
    }
  };
  const handleNext = () => {
    if (currentPage < data.length) {
      setCurrentPage((prev) => prev + numberOfSelection);
    }
  };

  const handleSearch = ({ target: { value } }, name) => {
    setSearchTerm(value);
    let newFilteredData = data.filter((item) =>
      item[name].toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredData(newFilteredData);
    setCurrentPage(0);
  };

  useEffect(() => {
    setIsLoading(true)
    setTimeout(()=>{
      setNewData(
        filteredData?.slice(currentPage, numberOfSelection + currentPage)
      );
      setIsLoading(false)
    },1000)
  }, [currentPage, filteredData]);

  useEffect(()=>{
    setCurrentPage(0)
  },[itemsPerPage])

  return (
    <div className="w-full bg-white dark:bg-gray-800 rounded-md">
      {data.length > 0 && filterItem && (
        <div className="mb-10 flex justify-end items-center gap-2">
          {filterItem.map((item, index) => (
            <label
              key={index}
              className="flex flex-col sm:flex-row items-center gap-2 text-slate-600 dark:text-slate-100 transition-all duration-500"
            >
              Search by {item[0].toUpperCase() + item.slice(1)}
              <input
                name={item}
                type="text"
                className="py-1 px-2 text-sm min-w-[100px] text-black dark:text-white bg-white dark:bg-slate-800 rounded-full border-0 outline-none ring-1 ring-slate-300 dark:ring-white transition-all duration-500"
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e, item);
                }}
              />
            </label>
          ))}
        </div>
      )}
    
      <div className="w-full flex flex-col">
        <div className="w-full">
          {children({ data: newData })}
        </div>
        
        {/* PAGINATION BUTTON */}
        {(data.length > itemsPerPage) &&
          <div className='p-2 w-full flex flex-col lg:flex-row justify-center items-center gap-3 md:gap-6'>
            <div className="text-sm text-center lg:text-left font-normal text-gray-500 dark:text-gray-400 block w-full">Showing <span className="font-semibold text-gray-900 dark:text-white">
              {isLoading ? '----' : `${currentPage + 1}-${currentPage + numberOfSelection >= data.length ? data.length : (currentPage + numberOfSelection)}`}</span> of <span className="font-semibold text-gray-900 dark:text-white">{data.length}</span>
            </div>
            <div className='flex items-center gap-3 md:gap-6'>
                <button 
                    onClick={handlePrev}
                    className={`flex gap-2 items-center px-2 py-[6px] rounded-[4px] bg-purple text-white ${currentPage == 0 ? 'bg-sky-600/50 dark:bg-slate-900/50 pointer-events-none' : 'bg-sky-600 dark:bg-slate-900'} text-white-light`} 
                    disabled={isLoading}
                >
                Prev
                </button>
                <button 
                    onClick={handleNext}
                    className={`flex gap-2 items-center px-2 py-[6px] rounded-[4px] bg-purple text-white ${currentPage + numberOfSelection >= data.length ? 'bg-sky-600/50 dark:bg-slate-900/50 pointer-events-none' : 'bg-sky-600 dark:bg-slate-900'} text-white-light`} 
                    disabled={isLoading}
                >
                Next
                </button>
            </div>
          </div>
        }
      </div>

      {/* show prev and next button if data exist */}
      {/* {data.length > 0 && (
        {data.length && data.map((item, index)=>{
          item = item
          if(index%itemsPerPage == 0 && index >= currentPage && index <= currentPage+itemsPerPage){
            return (
              <button
                key={index}
                onClick={handleNext}
                className={`w-6 h-6 md:w-12 md:h-12 text-sm md:text-lg rounded-full flex justify-center items-center border transition-all duration-300 ${
                  currentPage != index
                    ? "text-slate-400 border-slate-400 dark:text-slate-400 dark:border-slate-400"
                    : "text-slate-600 border-slate-600 dark:text-white dark:border-white pointer-events-none"
                }`}
              >
                {index/itemsPerPage +1}
              </button>
            )
          }
        })}
      </div>
      )} */}
    {isLoading && <TableIsLoading />}
    </div>
  );
}

const TableIsLoading = () => {
  return (
    <div className="w-full fixed z-[999] inset-0 flex justify-center items-center bg-black/10">
      <p className="rounded-md shadow-md p-4 bg-white/90 dark:bg-gray-900 text-brown dark:text-white">Loading...</p>
    </div>
  )
}

{/* <TableWrapper data={data} itemsPerPage={10}>
    {({ data }) => (
      <></>
    )}
</TableWrapper> */}
