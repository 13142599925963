import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary

const OverviewCharts = ({period, country}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const state = {         
        series: [{
          name: 'Patients',
        //   data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            // formatter: function (val) {
            //   return val + "%";
            // },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            //   formatter: function (val) {
            //     return val + "%";
            //   }
            }
          
          },
        //   title: {
        //     text: `Monthly Patient registration, ${new Date().getFullYear()}`,
        //     floating: true,
        //     offsetY: 330,
        //     align: 'center',
        //     style: {
        //       color: '#444'
        //     }
        //   }
        },
    };

    const [chartData, setChartData] = useState(state)

    useEffect(() => {
      setLoading(true)

      const fetchData = async () => {
          const token = getToken();
          console.log("Retrieved token:", token);
          if (!token) {
              setError('No token found. Please login again.');
              setLoading(false);
              return;
          }

          const myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append("Authorization", `Bearer ${token}`);

          const requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow"
          };

          try {
              const response = await fetch(`https://api.quickcare.ng/admin/overview/total-patients?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
              const result = await response.json();
              console.log("Data received from server:", result);

              if (response.ok) {
                  let seriesData = Object.values(result?.data).map(val => val)
                  let xaxisCategories = Object.keys(result?.data).map(key => key)
                  setChartData(prev => ({...prev, series: [{...prev.series[0], 'data':[...seriesData]}], options:{...prev.options, 'xaxis':{...prev.options.xaxis, 'categories':[...xaxisCategories]}}}));
              } else {
                  setError(result.message || 'Failed to fetch data');
              }
          } catch (error) {
              console.error("Fetch error:", error);
              setError(error.message);
          } finally {
              setLoading(false);
          }
      };
      
      fetchData();
    }, [period, country]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default OverviewCharts;
