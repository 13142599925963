import React, { useState } from 'react';
import data from '../component/data/consultations'
// import OverviewCharts from '../component/overview/OverviewCharts';
import OverviewBarCharts from '../component/overview/OverviewBarCharts';

// import OverviewStat from '../component/overview/OverviewStat';
import OverviewRecentConversations from '../component/overview/OverviewRecentConversations';

import OverviewCom from '../component/overview/Overview';


const Overview = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate index of the first and last item of current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Slice data array to get items for the current page
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    

    return (
        <>  
            <div className='flex flex-col gap-4 w-full'>
                <OverviewCom />
            </div>

            {/* <div className='w-full mt-6 p-3 bg-white rounded-lg'>
                <div className='w-full flex items-center justify-between font-semibold mb-3'>
                    <p>
                        Total Patients Per Month
                    </p>

                </div>
            
                <OverviewBarCharts />
            </div> */}

            {/* <OverviewRecentConversations /> */}
        </>
    );
};

export default Overview;
