import React, { useState } from 'react';

import FinanceStats from '../component/finance/FinanceStats';

const Financials = () => {
        const myDate = new Date()
        const MAX_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${myDate.getDate()  >= 10 ? myDate.getDate() : `0${myDate.getDate()}` }`
        const MIN_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${'01'}`
    
        const [period, setPeriod] = useState({
            start_date: MIN_DATE,
            end_date: MAX_DATE
        });
        
        const [country, setCountry] = useState('Kenya'); // Default to Kenya

        const handlePeriodChange = ({target:{name, value}}) => {
            setPeriod(prev => ({...prev, [name]:value}))
        }


    return (
        <>
            <div className='flex flex-col gap-4 w-full'>
                <div className='flex w-full items-center justify-end gap-2 lg:gap-4'>
                    <div className='flex flex-col sm:flex-row gap-2 lg:gap-4'>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>Start Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                max={period.end_date}
                                name='start_date' 
                                value={period.start_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>End Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                min={period.start_date}
                                max={MAX_DATE}
                                name='end_date' 
                                value={period.end_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                    <label className='text-black font-bold'>Country</label>
                        <select id="country" value={country} onChange={(e) => setCountry(e.target.value)}
                            className='p-3 rounded-lg bg-white outline-0'>
                            <option value="kenya">Kenya</option>
                            <option value="nigeria">Nigeria</option>
                        </select>
                    </div>
                </div>

                <FinanceStats period={period} country={country} />

                {/* <div className='w-full mt-6 p-3 bg-white rounded-lg'>
                    <div className='w-full flex items-center justify-between font-semibold mb-3'>
                        <p className='capitalize'>
                        revenue
                        </p>

                        <select name="time" id="" className='outline-0' onChange={(e) => setCountry(e.target.value)} value={country}>
                            <option value="Nigeria">Naira</option>
                            <option value="Kenya">Ksh</option>
                        </select>
                    </div>
                    {country === 'Nigeria' ? <FinancialData /> : <FinancialChartKenya />}
                </div> */}
            </div>

        </>
    );
};

export default Financials;
