import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/getToken'; // Adjust the import path as necessary
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import PaidIcon from '@mui/icons-material/Paid';
import { NumberTo2DP } from '../../utils/NumberTo2DP';

const OverviewStat = ({period, country}) => {
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = getToken();
            console.log("Retrieved token:", token);
            if (!token) {
                setError('No token found. Please login again.');
                setLoading(false);
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            setLoading(true)
            try {
                const response = await fetch(`https://api.quickcare.ng/admin/overview/stats?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
                const result = await response.json();
                console.log("Data received from server: now", result);
                if (response.ok) {
                    setStats(result.data);
                } else {
                    setError(result.message || 'Failed to fetch stats');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [period, country]);

    return (
        <div className="overview-stat">
            <div className='flex flex-col gap-4 w-full'>
                    <p className='text-xl text-[#424242] font-semibold'>
                        Overview
                    </p>

                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 w-full items-center justify-between gap-3
                    lg:justify-start lg:gap-5'>
                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Overall Patients
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.total_patients)}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#88DF54] flex items-center justify-center'>
                                {/* <HeadsetMicIcon /> */}
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Overall patients to date
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.total_patients_by_date)}
                                {/* {NumberTo2DP(stats.total_conversations)} */}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Overall acquired customers
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.total_acquired_customers)}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Acquired customer to date
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.total_acquired_customers_by_date)}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PersonIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Today acquired customers
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.acquired_customers_per_day)}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PaidIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Total revenue to date
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.total_revenue_by_date)}
                                </p>
                            </div>
                        </div>

                        <div className='flex items-center gap-1 bg-white shadow p-4 rounded-lg'>
                            <div className='p-2 w-10 h-10 rounded-full text-white bg-[#1772B3] flex items-center justify-center'>
                                <PaidIcon />
                            </div>

                            <div className='flex flex-col gap-[1px]'>
                                <p className='font-semibold text-[#646464] text-base'>
                                Daily Revenue
                                </p>
                                <p className='text-[#424242] text-lg'>
                                {loading ? <span className='text-sm'>Loading...</span> : error ? <span className='text-sm text-red-500'>N/A</span> : NumberTo2DP(stats.daily_revenue)}
                                </p>
                            </div>
                        </div>
                </div>
            </div>

           
        </div>
    );
};

export default OverviewStat;
