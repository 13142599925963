import React, { useState, useEffect } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { getToken } from '../utils/getToken'; // Import the getToken function
import { NumberTo2DP } from '../utils/NumberTo2DP';

import MarketTable from '../component/market/MarketTable'

const MarketingPage = () => {

    const myDate = new Date()
    const MAX_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${myDate.getDate()  >= 10 ? myDate.getDate() : `0${myDate.getDate()}` }`
    const MIN_DATE = `${myDate.getFullYear()}-${myDate.getUTCMonth() + 1 > 10 ? myDate.getUTCMonth() : `0${myDate.getUTCMonth()+1}`}-${'01'}`

    const [period, setPeriod] = useState({
        start_date: MIN_DATE,
        end_date: MAX_DATE
    });
    
    const [country, setCountry] = useState('Kenya'); // Default to Kenya

    const handlePeriodChange = ({target:{name, value}}) => {
        setPeriod(prev => ({...prev, [name]:value}))
    }

    const [marketStats, setMarketStats] = useState({error:false, loading: true, data:{}});

    useEffect(() => {
        const fetchStats = async () => {
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const token = getToken(); // Retrieve the token from localStorage
            if (token) {
                console.log('Retrieved token:', token);
                myHeaders.append("Authorization", `Bearer ${token}`);
            } else {
                console.error('No token found');
                return;
            }

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            setMarketStats(prev => ({...prev, error:false, loading:true}));
            try {
                const response = await fetch(`https://api.quickcare.ng/admin/marketing/marketer-stats?start_date=${period.start_date}&end_date=${period.end_date}&country=${country}`, requestOptions);
                const result = await response.json();

                // Extracting data from the result
                const data = result?.data;
                setMarketStats({loading:false, error:false, data:data});
            } catch (error) {
                setMarketStats({loading:false, error:true, data:{}});
            }
        };

        fetchStats();
    }, [period, country]);

    return (
        <>
            <div className='flex flex-col gap-4 w-full'>
                <p className='text-xl text-[#424242] font-semibold'>
                    Marketing
                </p>

                <div className='flex w-full items-center justify-end gap-2 lg:gap-4'>
                    <div className='flex flex-col sm:flex-row gap-2 lg:gap-4'>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>Start Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                max={period.end_date}
                                name='start_date' 
                                value={period.start_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='text-black font-bold'>End Date</label>
                            <input 
                                // className='p-3 rounded-lg bg-white outline-0' 
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                min={period.start_date}
                                max={MAX_DATE}
                                name='end_date' 
                                value={period.end_date} 
                                type='date' 
                                onChange={handlePeriodChange} 
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                    <label className='text-black font-bold'>Country</label>
                        <select id="country" value={country} onChange={(e) => setCountry(e.target.value)}
                            className='p-3 rounded-lg bg-white outline-0'>
                            <option value="kenya">Kenya</option>
                            <option value="nigeria">Nigeria</option>
                        </select>
                    </div>
                </div>

                <MarketTable tableData={marketStats} />
            </div>
        </>
    );
};

export default MarketingPage;



const data = {
    "Mobipium Nigeria": {
        "total_customers": 11,
        "total_revenue": 0,
        "active_customers": 11,
        "monthly_revenue": 0,
        "monthly_customers": 11,
        "churn_rate": 0
    },
    "AET Ads": {
        "total_customers": 6584,
        "total_revenue": 0,
        "active_customers": 6574,
        "monthly_revenue": 0,
        "monthly_customers": 6584,
        "churn_rate": 669
    },
    "Angel Media": {
        "total_customers": 17570,
        "total_revenue": 20,
        "active_customers": 17549,
        "monthly_revenue": 20,
        "monthly_customers": 17570,
        "churn_rate": 1804
    },
    "MobTek Publishers": {
        "total_customers": 14,
        "total_revenue": 0,
        "active_customers": 14,
        "monthly_revenue": 0,
        "monthly_customers": 14,
        "churn_rate": 2
    }
}
